<template>
  <v-container class="py-0">
    <base-material-card
      :title="$t('list', { name: $tc('user', 2) })"
      icon="mdi-account-multiple"
    >
      <v-row justify="start">
        <!--
        <v-col cols="12" md="2">
          <v-text-field
            outlined
            dense
            v-model="filters.search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
            @input="searchFilter"
          />
        </v-col>
        -->
        <v-col
          class="my-0 py-0"
          cols="12"
          md="2"
          v-if="
            $store.getters['auth/isAdmin'] ||
            $store.getters['auth/isMainStudio'] ||
            $store.getters['auth/isTattooer']
          "
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="
                  $store.getters['auth/getPlan']('n_users') <= items.length
                "
                v-on="on"
                icon
                @click="$router.push({ name: 'userCreate' })"
                elevation="0"
              >
                <v-icon size="36">mdi-plus-circle</v-icon>
              </v-btn>
            </template>
            {{ $t("new", { name: $tc("user") }) }}
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="3"
          v-for="(user, i) in items"
          :key="i"
          class="py-0"
        >
          <user-card @update="fetchUsers" :user="user"></user-card>
        </v-col>
      </v-row>

      <!--
         <v-pagination v-model="page" :length="pagesLength"></v-pagination>
      -->
    </base-material-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import UserRole from "@/components/admin/Role";
import { debounce } from "lodash";

export default {
  name: "UserList",
  components: {
    UserRole,
    UserCard: () => import("@/components/user/Card"),
  },
  data() {
    return {
      items: [],
      filters: {
        search: "",
      },
      page: 1,
      totalUsers: 0,
      sortBy: "first_name",
      loading: true,
    };
  },
  computed: {
    itemsPerPagee() {
      /*
      if (this.$vuetify.breakpoint.lgAndDown) {
        return 7;
      }
      */
      return 30;
    },

    pagesLength() {
      return Math.ceil(this.totalUsers / this.itemsPerPagee);
    },
  },
  mounted() {
    this.fetchUsers();
    console.log(this.$store.getters["auth/getPlan"]("n_users"));
  },
  watch: {
    page: {
      handler() {
        this.fetchUsers();
      },
      deep: true,
    },
    itemsPerPagee: {
      handler() {
        this.fetchUsers();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("users", ["getUsers"]),
    editUser(item) {
      this.$router.push({
        name: "userEdit",
        params: {
          id: item.id,
        },
      });
    },
    resetPassword(item) {
      //console.log(item, "reset");
    },
    fetchUsers() {
      this.loading = true;
      this.getUsers({
        pagination: { itemsPerPage: this.itemsPerPagee, page: this.page },
        filters: this.filters,
      }).then((users) => {
        this.items = users.data;
        this.totalUsers = users.total;
        this.loading = false;
      });
    },
    searchFilter: debounce(function (val) {
      this.fetchUsers();
    }, 1000),
  },
};
</script>
<style lang="scss" scoped>
$hex-height: 230px;
$hex-height-small: 200px;
$hex-height-small-min: 32vh;
$hex-width-small-min: 16vw;
$hex-width-min: 260px;

.hexagon {
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  width: $hex-width-min;
  min-height: $hex-height;
  background-color: var(--v-gris3-base);
  margin: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px solid green;
}

.row2 {
  width: $hex-width-min;
  margin-left: 56%;
}
.up {
  margin-top: -$hex-height/4 !important;
}
.emptyRow {
  max-width: 11.666667% !important;
}
.hexagonparent {
  filter: drop-shadow(0 0 8px black);
  min-width: 250px;
}
@media only screen and (min-width: 1435px) {
}

@media only screen and (max-width: 1905px) {
  .hexagon {
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    min-width: 200px;
    min-height: 230px;
    background-color: var(--v-gris3-base);
    padding-top: 4%;
    margin: 0.1em;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px solid green;
  }

  .row2 {
    margin-left: 5%;
    width: $hex-width-min;
  }
  .up {
    margin-top: -$hex-height-small/4 !important;
  }
  .hexagonparent {
    filter: drop-shadow(0 0 8px black);
  }
}

@media (max-width: 960px) {
  .hexagon {
    min-width: 200px;
    min-height: 230px;
  }
}
@media (max-width: 600px) {
  .hexagon {
    min-width: 200px;
    min-height: 260px;
  }
}
@media (max-width: 1300px) {
  .hexagonparent {
    min-width: 230px;
  }
}
</style>
<style lang="sass">
.v-pagination__navigation, .v-pagination__navigation--disabled, .v-pagination__item
  box-shadow: none !important
</style>